import useForm from "../../../hooks/Common/useForm";
import React from "react";
import GridContainer from "../../../@jumbo/components/GridContainer";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "../../Common/Forms/OutlinedInput";
import {useSelector} from "react-redux";
import {useIntl} from "react-intl";

const INIT_DATA = {
    id: '',
    serviceId: '',
    quantity: 1,
    unitPrice: 1,
}

const ServiceForm = ({id, service = {}, onSubmit}) => {
    const {formData, setFormData, handleSubmit, setValues} = useForm(INIT_DATA);
    const intl = useIntl();
    const {currentCreditNote} = useSelector(({treasury}) => treasury.creditNotes);
    React.useEffect(() => {
        service && setValues({
            ...service,
            quantity: service.quantity || service.referenceQuantity,
        });
    }, []);

    return (
        <form id={id} onSubmit={handleSubmit(onSubmit)}>
            <GridContainer spacing={2}>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='quantity'
                        type='number'
                        label={`NC ${intl.messages["quantity"]}`}
                        value={formData.quantity}
                        onChange={value => +value <= service.referenceQuantity && setFormData('quantity', value)}
                        fullWidth
                        inputProps={{min: 1}}
                        required
                        disabled={currentCreditNote?.sunatSent}
                        error={`${intl.messages["max"]}: ${service.referenceQuantity}`}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <OutlinedInput
                        name='unitPrice'
                        type='number'
                        label={intl.messages["unit.price"]}
                        value={formData.unitPrice}
                        onChange={value => +value <= service.unitPrice && setFormData('unitPrice', value)}
                        fullWidth
                        inputProps={{min: 1, step: 0.01}}
                        required
                        disabled={currentCreditNote?.sunatSent}
                    />
                </Grid>
            </GridContainer>
        </form>
    );
};

export default ServiceForm;