import React from "react";
import StyledTableRow from "../../../../Common/Tables/StyledTableRow";
import StyledTableCell from "../../../../Common/Tables/StyledTableCell";
import IntlMessages from "../../../../../@jumbo/utils/IntlMessages";
import Checkbox from "../../../../Common/Forms/Checkbox";
import TableCellWithSorting from "../../../../Common/Tables/TableCellWithSorting";

const TableHeader = ({selectAll, setSelectAll, orderBy, order, onSort}) => (
  <StyledTableRow>
    <StyledTableCell>
      <Checkbox
        checkBoxStyle={{
          color: "white"
        }}
        checked={selectAll}
        onChange={value => setSelectAll(!selectAll)}
      />
    </StyledTableCell>
      <TableCellWithSorting
          property="taskStartDate"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
      >
          <IntlMessages id="fleet.management.tachograph.files.table.task.start.date" />
      </TableCellWithSorting>
      <TableCellWithSorting
          property="taskEndDate"
          orderBy={orderBy}
          order={order}
          onSort={onSort}
      >
          <IntlMessages id="fleet.management.tachograph.files.table.task.end.date" />
      </TableCellWithSorting>
    <TableCellWithSorting
        property="date"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id="fleet.management.tachograph.files.table.date" />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="proc"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id="source" />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="plaque"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id="fleet.management.tachograph.files.table.plaque" />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="card"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id="fleet.management.tachograph.files.table.card" />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="driver"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id="fleet.management.tachograph.files.table.driver" />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="fileName"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id="fleet.management.tachograph.files.table.name" />
    </TableCellWithSorting>
    <TableCellWithSorting
        property="standard"
        orderBy={orderBy}
        order={order}
        onSort={onSort}
    >
      <IntlMessages id="fleet.management.tachograph.files.table.standard" />
    </TableCellWithSorting>
  </StyledTableRow>
);

export default TableHeader;
